import { ref } from "vue";

let scrollOnRouteChangeIsDisabled = false;

export function getScrollOnRouteChangeIsDisabled() {
  return scrollOnRouteChangeIsDisabled;
}

export async function withNoScroll(f: () => Promise<void>) {
  try {
    scrollOnRouteChangeIsDisabled = true;
    await f()
  }
  finally {
    scrollOnRouteChangeIsDisabled = false;
  }
}

/**
 * Scroll pos, not of the window, but of the route root element container
 */
export const routeRootScrollPos = ref({left: 0, top: 0});

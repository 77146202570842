import { FormatRegistry } from '@sinclair/typebox'
import * as t from "@sinclair/typebox"

// we deal with GUIDs from the db that are always entirely uppercase
// Investigate: globally augment `t` to have a well-typed `t.Guid()`
export const k_GUID = "GUID"
FormatRegistry.Set(k_GUID, (value) => /^[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}$/.test(value))

export const k_DateLike = "DateLike"
export const k_DatetimeLike = "DatetimeLike"
FormatRegistry.Set(k_DateLike, (value) => !isNaN(new Date(value as any).getTime()))
FormatRegistry.Set(k_DatetimeLike, (value) => !isNaN(new Date(value as any).getTime()))

export const queryBool = () => t.String({pattern: "^0|1$"})
export const queryIntegerlike = () => t.String({pattern: t.PatternNumberExact})
